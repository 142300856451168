import React from 'react'
import {Typography, Card} from "@mui/material";
import Layout from '../layouts';

const ServicesPage = () => (
    <Layout pageName="Services">
        <Card style={{padding:'20px'}}>
            <Typography variant="h6" style={{color:'#080',fontWeight:'bold'}}>Customized Training or Prepared Modules, Coaching, or
                Performance Improvement Programs Based on Your Needs. </Typography>

            <h3 style={{textDecoration:'underline'}}>LeaderShip Skills</h3>
            <ul>
                <li>Co-create a Team Vision and Guiding Principles</li>
                <li>Lead from the Inside Out - Mastering Leadership Skills</li>
                <li>Coach for Results</li>
                <li>Thriving in Constant Change</li>
                <li>Leading Diverse Teams Effectively</li>
            </ul>

            <h3 style={{textDecoration:'underline'}}>Team Building</h3>
            <ul>
                <li>Build a Great Team</li>
                <li>Conflict Management Skills that Work!</li>
                <li>Myers Briggs Temperament Indicator; Working Well With Different Personality Types</li>
            </ul>

            <h3 style={{textDecoration:'underline'}}>Professional Skills</h3>
            <ul>
                <li>Customer Service Excellence</li>
                <li>Cultivate Life/Work Balance</li>
                <li>Do Presentations with Confidence!</li>
                <li>Maximize Your Creativity at Work</li>
                <li>Manage Stress through Mindfulness and Inquiry</li>
                <li>Emotional Intelligence (The Secret to Effectiveness, and Happiness)</li>
                <li style={{color:'#080',fontWeight:'bold'}}>Many other training modules available based on your needs</li>
            </ul>

            <h3><b>Some of our consulting services include:</b></h3>
            <ul>
                <li>Excellent Retreat Facilitation (Team Building, Strategic Planning, Community Building are some of
                    our most popular retreats)</li>
                <li>One on One <span style={{color:'#080',fontWeight:'bold'}}>Coaching</span> to Enhance Performance
                    and Skills Development</li>
                <li><b>Organizational Development Projects: Interventions, Performance Improvement, Conflict
                    Resolution</b> and <b>Mediation</b></li>
            </ul>

            <div>
                <blockquote style={{color:'#080',maxWidth:'400px',margin:'0 auto',textAlign:'center'}}>"Change the way
                    you look at things and the things you look at change."
                    <br/><br/><small><em>- Dr. Wayne Dyer</em></small></blockquote>
            </div>
        </Card>
    </Layout>
);

export default ServicesPage;
